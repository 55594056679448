import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import SEO from '../../utils/SEO';
import { keywords } from '../../utils/keywordsSEO';

const NoMatch = () => {
  return (
    <Container
      sx={{
        width: '100vw',
        height: '80vh',
        display: 'flex',
        flexFlow: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        backgroundImage: 'linear-gradient(90deg, var(--primary), var(--secondary))',
        backgroundClip: 'text',
      }}
    >
      <ErrorIcon
        color='secondary'
        sx={{ 
          fontSize: 80, 
          opacity: .6,
        }}
      />
      <Typography
        variant="h3" gutterBottom
        sx={{
          width: 'auto',
          height: 'auto',
          opacity: '.8',
          color: 'transparent',
          p: '2rem',
        }}
      >
        There is nothing here! 
      </Typography>
      <SEO 
          title='mavupload - nomatch'
          description='Amazing and high quality artworks, Portrait, Painting, Drawing'
          type='Image'
          name='mavupload'
          keywords={keywords}
      />
    </Container>
    
  )
}

export default NoMatch