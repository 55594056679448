import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { firebase_storage, firestore} from '../../firebase/firebaseConfig'
import { useAuth } from '../../context/AuthContext';
import { useState, useEffect } from 'react';
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';
import { useSnackbar } from "notistack";
import { useArtContext, useUpdateArtContext } from '../../context/ArtContext';

const UploadArtwork = () => {
    const { enqueueSnackbar } = useSnackbar();
    const state = useArtContext();
    const updateState = useUpdateArtContext();
    const auth = useAuth()
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        // references
        const storageRef = ref(firebase_storage, `artworks_images/${auth.user.uid}/${Date.now() + auth.user.uid + state.image.name}`);
        const collectionRef = collection(firestore, 'artworks_projects');
        const uploadTask = uploadBytesResumable(storageRef, state.image);

        uploadTask.on('state_changed', (snapshot) => {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(percentage);
        }, (error) => {
            enqueueSnackbar(error.message, { variant: "error" });
            updateState.setError(error.code);
            console.log('upload image error', error.message);
            
        }, async () => {
            const url = await getDownloadURL(storageRef);
            const createdAt = serverTimestamp();
            await addDoc(collectionRef, {
                title: state.title,
                description: state.description,
                artist_name: auth.user.artist_name,
                artist_uid: auth.user.uid,
                images_url: url,
                createdAt
            }).then(() => {
                enqueueSnackbar('Artwork uploaded', { variant: "success" });
                console.log('create project success');
            }).catch((error) => {
                enqueueSnackbar(error.message, { variant: "error" });
                updateState.setError(error.code);
                console.log('create project error', error.message);
            });

            return () => {
                console.log('cleanup function in')
                updateState.setTitle('');
                updateState.setDescription('');
                updateState.setImage(null);
                updateState.setImageUrl(null);
                updateState.setUploadedUrl(null);
                updateState.setError(null);
                updateState.setUploading(false);
                updateState.setUploaded(false);
            }
            
        });
    }, [auth, state, updateState, enqueueSnackbar]);

    return { progress };
}

export default UploadArtwork;