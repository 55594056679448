import React, { Component } from "react";
import { HelmetProvider } from 'react-helmet-async';
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./utils/muiTheme";
import './assets/style/globalStyle.css';
import Routers from "./router/Routers";
import { ArtworkProvider } from "./context/ArtContext";

const helmetContext = {};
class App extends Component {
  render() {
    return (
      <HelmetProvider context={helmetContext}>
        <ThemeProvider theme={theme} >
          <ArtworkProvider>
            <AnimatePresence mode="wait">
              <Routers />
            </AnimatePresence>
          </ArtworkProvider>
        </ThemeProvider>
      </HelmetProvider>
    
    )
  }
}

export default App;
