import React, {useMemo, useState} from 'react'

export const ArtContext = React.createContext({
    title:'',
    description:'', 
    image:null,
    imageUrl:null,
    uploadedUrl:null,
    error:null,
    uploading:false,
    uploaded:false,
});
export const UpdateArtContext = React.createContext({
    setTitle: (callback) => {},
    setDescription: (callback) => {},
    setImage: (callback) => {},
    setImageUrl: (callback) => {},
    setUploadedUrl: (callback) => {},
    setError: (callback) => {},
    setUploading: (callback) => {},
    setUploaded: (callback) => {},
});

export const useArtContext = () => {
  return React.useContext(ArtContext);
}

export const useUpdateArtContext = () => {
    return React.useContext(UpdateArtContext);
}

export const ArtworkProvider = ({ children }) => {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null); 
    const [uploadedUrl, setUploadedUrl] = useState(null); 
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    const state = useMemo(() => {
        return {
            title,
            description, 
            image,
            imageUrl,
            uploadedUrl,
            error,
            uploading,
            uploaded,
        }
    }, [title, description, image, imageUrl, uploadedUrl, error, uploading, uploaded])

    const setState = useMemo (() => {
        return {
            setTitle,
            setDescription,
            setImage,
            setImageUrl,
            setUploadedUrl,
            setError,
            setUploading,
            setUploaded,
        }
    }, [])

    return (
        <ArtContext.Provider value={state}>
            <UpdateArtContext.Provider value={setState}>
                { children }
            </UpdateArtContext.Provider>
        </ArtContext.Provider>
    )

}

export default ArtContext