import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { theme } from '../../utils/muiTheme';
import { MotionCard, MotionCardMedia } from '../../utils/motionMui';


const Modal = (props) => {
  return (
    <MotionCard 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1, }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        sx={{ 
            display: 'flex',
            flexFlow: 'row nowrap' ,
            m: '0.5rem', 
            ":hover": {cursor: 'pointer'},
            // maxHeight:'50vw',
            // maxWidth: '150vh',
            width: '99vw',
            height: 'auto',
            [theme.breakpoints.down('md')]: {
                flexFlow: 'column nowrap',
                maxHeight: '95vh',
                height: 'auto',
                maxWidth: '99vw',
                width: 'auto',
            },
        }}
    >
        <Box 
            sx={{ 
                display: 'flex', 
                flexFlow: 'column nowrap',
                maxWidth:"25%",
                width: "25%",
                color:'primary.dark',
                bgcolor:'primary.light',

                [theme.breakpoints.down('md')]: {
                    maxHeight:"20%",
                    height: "20%",
                    maxWidth: '100%',
                    width: 'auto',
                },
            }}
        >
            <CardContent 
                sx={{ 
                    flex: '1 0 auto', 
                    color:'primary.dark',
                    bgcolor:'primary.light',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    justifyContent: 'space-between',
                    [theme.breakpoints.down('md')]: {
                        flexFlow: 'row nowrap',
                        width: '100%',
                        fontSize: '0.4rem'
                    },
                }}
            >
                <Typography component="div" variant="h5">
                    {props.selectedArt.title}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div" whiteSpace={'pre-wrap'}>
                    {props.selectedArt.description}
                </Typography>
                <Typography 
                    component="div" 
                    variant="subtitle2"
                    sx={{

                    }}
                >
                    {"By " + props.selectedArt.artist_name}
                </Typography>
            </CardContent>
        </Box>
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                maxWidth:"75%",
                width: "75%",
                [theme.breakpoints.down('md')]: {
                    maxHeight: '90vh',
                    height: 'auto',
                    maxWidth: '100%',
                    width: 'auto',
                    
                },
            }}
        >
            <MotionCardMedia
                initial={{ opacity: 0,}}
                animate={{ opacity: 1, }}
                transition={{ ease: "easeOut", duration: 1 }}
                component="img"
                sx={{
                    opacity: 1,
                }}
                image={props.selectedArt.images_url}
                alt={"Enlarged " + props.selectedArt.title}
            />
        </Box>
    </MotionCard>
  )
}

export default Modal