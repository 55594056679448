

export const artworkData = [
    {
      image_url: 'https://images.unsplash.com/photo-1648877076243-59184cdf49aa',
      title: 'Abstract Red Green',
      description: '',
      artist_name: 'MAV',
      id: 1,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877076238-672951a8f420',
      title: '1819 Sitting Room',
      description: '',
      artist_name: 'MAV',
      id: 2,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877075697-5d15a8871b1c',
      title: 'Portrait Monroe',
      description: '',
      artist_name: 'MAV',
      id: 3,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877075365-122f352a73a2',
      title: 'Red Warrior',
      description: '',
      artist_name: 'MAV',
      id: 4,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877075670-f4f6ec3fb65b',
      title: 'Red in dark',
      description: '',
      artist_name: 'MAV',
      id: 5,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877075369-f30525f7e51c',
      title: 'Blue warrior',
      description: '',
      artist_name: 'MAV',
      id: 6,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648876819972-ddccccaa653d',
      title: '1819 Illustrated',
      description: '',
      artist_name: 'MAV',
      id: 7,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877076266-8ae0fbee1bd2',
      title: 'Red Hat',
      description: '',
      artist_name: 'MAV',
      id: 8,
    },
    {
      image_url: 'https://images.unsplash.com/photo-1648877075649-81e3e5b6d294',
      title: 'Red in black bloom',
      description: '',
      artist_name: 'MAV',
      id: 9,
    },
];