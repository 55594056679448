
// Authentication action types
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const USE_GOOGLE_ERROR = 'USE_GOOGLE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const USE_GOOGLE_SUCCESS = 'USE_GOOGLE_SUCCESS';
export const SET_USER = "SET_USER";
export const SIGN_OUT = "SIGN_OUT";
export const LOGIN = "LOGIN";

