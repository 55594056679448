import React, { useEffect, useMemo, useRef } from 'react';
import ProgressBar from './ProgressBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import mavLogoTrans from '../../assets/images/Logo-Color-trans.png';
import { Copyright } from '../../components/Copyright';
import { useFirestore } from '../../firebase/useFirestore';
import { MotionGrid } from '../../utils/motionMui';
import './Upload.css';
import { useArtContext, useUpdateArtContext } from '../../context/ArtContext';
import SEO from '../../utils/SEO';
import { keywords } from '../../utils/keywordsSEO';

const Uploads = () => {
    const { docs } = useFirestore('artworks_projects');
    const render = useRef(1);
    const titleRef = useRef('');
    const descriptionRef = useRef('');
    const state = useArtContext();
    const updateState = useUpdateArtContext();
  
    const randomImageUrl = useMemo(() => {
            const rand = Math.floor(Math.random() * docs.length);
            const url = (docs && docs[rand]) ? `url(${docs[rand].images_url})` : `url(${mavLogoTrans})`
            return url;
    }, [docs])

  const types = ['image/png', 'image/jpeg', 'image/svg', 'image/webp'];

  const handleImageChange = (e) => {

    let selectedImage = e.target.files[0];

    if (selectedImage && types.includes(selectedImage.type)) {
      updateState.setImage(selectedImage);
      updateState.setImageUrl(URL.createObjectURL(selectedImage));
      updateState.setError(null);
    } else {
      updateState.setImage(null);
      updateState.setImageUrl(null);
      updateState.setError('Please select an image file (svg, png or jpg)');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateState.setTitle(titleRef.current.value);
    updateState.setDescription(descriptionRef.current.value);
    console.log(state)
    // console.log(`${descriptionRef.current.value}`)
    state.image && updateState.setUploading(true);
  };

  useEffect(() => {
      render.current = render.current + 1;
  })

  console.log(render.current)

  return (
    <MotionGrid 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ ease: "easeOut", duration: 1 }}
    container 
    sx={{ height: '95vh' }}>
      <ScopedCssBaseline />
      <Grid 
        item 
        xs={12} 
        sm={8} 
        md={5} 
        sx={{ 
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? 
              t.palette.primary.subtle : 
              t.palette.primary.dark
          }} 
        component={Paper} 
        elevation={0} 
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
            <CloudUploadSharpIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Upload a New Artwork
          </Typography>
          {
            !state.uploading ?
            <Box
              component="form"
              sx={{mt: 3,}}
              onSubmit={(e) => handleSubmit(e)}
            >
              <label required className='upload__btn'>
                <input required type="file"
                    onChange={(e) => handleImageChange(e)} 
                />
                {
                  !state.image ?
                  <lord-icon
                    src="https://cdn.lordicon.com/mecwbjnp.json"
                    trigger="loop"
                    colors="primary:#f1c40f,secondary:#00a651"
                    stroke="50"
                    style={{width:"45px", height:"45px"}}>
                  </lord-icon>
                  :
                  <lord-icon
                    src="https://cdn.lordicon.com/mecwbjnp.json"
                    trigger="hover"
                    colors="primary:#f1c40f,secondary:#00a651"
                    stroke="50"
                    style={{width:"45px", height:"45px"}}>
                  </lord-icon>
                }
              </label>
              <Box>
                { state.error && <div className="error">{ state.error }</div>}
                
                { state.image && 
                  <TextField
                    id="standard-read-only-input"
                    color='google'
                    hiddenLabel
                    defaultValue={state.image.name}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment 
                          position="end"
                          size="small"
                        >
                          <IconButton
                            onClick = {
                              () => {
                              // setState({...state, image: null, imageUrl: null} )
                              updateState.setImage(null);
                              updateState.setImageUrl(null);
                            }}
                            onMouseDown={
                            () => {
                              // setState({...state, image: null, imageUrl: null} )
                              updateState.setImage(null);
                              updateState.setImageUrl(null);
                            }}
                            edge="end"
                            size="small"
                            disableRipple
                          >
                            <DeleteIcon 
                              color='google' 
                              fontSize="small"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    sx={{ mt: 2,}}
                  />
                }
              </Box>
              <TextField
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                inputRef = {titleRef}
                autoFocus
                color='google'
                size='small'
                sx={{mt: 2, backgroundColor: (t) => t.palette.mode === 'light' ? 
                  t.palette.background.main : 
                  t.palette.background.dark
                }}
              />
              <TextField
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="description"
                inputRef={descriptionRef}
                autoFocus
                color='google'
                multiline
                maxRows={4}
                size='medium'
                sx={{mt: 3, backgroundColor: (t) => t.palette.mode === 'light' ? 
                  t.palette.background.main : 
                  t.palette.background.dark
                }}
              />
              <Button
                  disableElevation
                  type="submit"
                  fullWidth
                  variant="contained"
                  size='large'
                  color='success'
                  startIcon={<FileUploadOutlinedIcon />}
                  sx={{ mt: 3, mb: 2, }}
                >
                  Upload
                </Button>
            </Box>
            :
            <Box>
              {
                state.image && state.title && state.description && 
                <Box className='uploading__progress'>
                  <ProgressBar /> 
                  <Typography
                    variant='caption' 
                    color='primary.dark'
                    sx={{mt: 2}}
                  >
                    Uploading ...
                  </Typography>
                </Box>
              }
            </Box>
          }
          
        </Box>
      </Grid>
        <MotionGrid
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          item
          xs={0}
          sm={4}
          md={7}          
          sx={{
            backgroundImage: (state && state.image && state.imageUrl) 
              ? `url(${state.imageUrl})` 
              : randomImageUrl,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.primary.light : t.palette.primary.dark,
            backgroundSize: (docs[1] || state.image) ? 'cover' : 'contain',
            backgroundPosition: 'center',
            display: {md:'flex', sm:'flex', xs:'none'},
          }}
        >
          <Box sx={{p:'1rem', alignSelf:'flex-end', width:'100%', opacity:'0.4'}}>
            <Copyright />
          </Box>
        </MotionGrid>

        <SEO 
          title='mavupload - upload'
          description='Upload Amazing and high quality artworks'
          type='Image'
          name='mavupload'
          keywords={keywords}
        />
    </MotionGrid>
  )
}

export default Uploads
