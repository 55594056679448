import React from "react";
import { NavLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import { theme } from '../../utils/muiTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const TabCode = (value, handleChange, label1, label2) => {
    return (
        <Tabs
            value={value} 
            onChange={handleChange} 
            indicatorColor="secondary"
            aria-label="navigation tabs"
            TabIndicatorProps={{
            style: { display: 'none' }
            }}
            sx={{
            textColor:"google",
            }}
        >
            <Tab  
                label={label1}
                icon={<DashboardRoundedIcon/>}
                iconPosition="start"
                component={NavLink} 
                to={"/dashboard"}
                style={({ isActive }) => ({ 
                    color: isActive ? 'var(--secondary)' : 'var(--google_blue)',
                })}
            />
            <Tab 
                label={label2}
                icon={<ShareRoundedIcon />}
                iconPosition="start"
                component={NavLink}
                to={"/upload"} 
                style={({ isActive }) => ({ 
                color: isActive ? 'var(--secondary)' : 'var(--google_blue)',
                })}
            />
        </Tabs>
    )
}

const NavBar = () => {

    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [value, setValue] = React.useState(0);
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box 
            sx={{ 
                display: 'flex',
                width: '100%',
                justifyContent:'flex-start',
                alignContent: "center",
            }}
        >
            {
                matches ? 
                (
                    TabCode(value, handleChange, 'Dashboard', 'Upload')
                ) 
                : 
                (
                    TabCode(value, handleChange, '', '')
                )
            } 
        </Box>
    )
}

export default NavBar
