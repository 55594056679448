import Container from '@mui/material/Container';
import React from 'react';
import { Copyright } from '../../components/Copyright';

const Footer = (props) => {
  return (
    <Container 
      sx={{
        height: '10vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Copyright />
    </Container>
  )
}

export default Footer