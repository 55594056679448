import { Container } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Button from '@mui/material/Button';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import GoogleIcon from '@mui/icons-material/Google';
import Typography from '@mui/material/Typography';
import { MotionGrid } from '../../utils/motionMui';
import { Copyright } from '../../components/Copyright';
import useFirestore from '../../firebase/useFirestore';
import mavLogoTrans from '../../assets/images/Logo-Color-trans.png';
import SEO from '../../utils/SEO';
import { keywords } from '../../utils/keywordsSEO';
import Login from './Login/login';
import Signup from './Signup/signup';

const Authentication = () => {

    const navigate = useNavigate()
    const auth =  useAuth();

    const { docs } = useFirestore('artworks_projects');
    const randomImageUrl = useMemo(() => {
        const rand = Math.floor(Math.random() * docs.length);
        const url = (docs && docs[rand]) ? `url(${docs[rand].images_url})` : `url(${mavLogoTrans})`
        return url;
    }, [docs])

    
    const [hasAccount, setHasAccount] = useState(true);
    const location = useLocation();
    const from = (location.state?.from?.pathname || "/");
    const fromText = from.replace('/', '');


    useEffect(() => {
        document.title = 'mavupload - Authentication';
    }, []);


    const handleUseGoogle = (e) => {
        e.preventDefault();
        auth.useGoogle(() => {
            navigate(from, { replace: true })
        })
    }

    return (
        <Grid container sx={{ height: '100vh' }} >
            <ScopedCssBaseline />
            <MotionGrid
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: "easeOut", duration: 1 }}
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                backgroundImage: randomImageUrl,
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.primary.light : t.palette.primary.dark,
                    backgroundSize: docs[1] ? 'cover' : 'contain',
                    backgroundPosition: 'center',
                    display: {md:'flex', sm:'flex', xs:'none'},
                }}
            >
                <Box sx={{p:'1rem', alignSelf:'flex-end', width:'100%', opacity:'0.4'}}>
                    <Copyright />
                </Box>
            </MotionGrid>

            <Grid 
                item 
                xs={12} 
                sm={8} 
                md={5} 
                sx={{ backgroundColor: (t) =>
                    t.palette.mode === 'light' ? 
                    t.palette.primary.subtle : 
                    t.palette.primary.dark,
                    py: 5,
                    px: 1,
                    }} 
                component={Paper} 
                elevation={0} 
                square
            >
                <Container>
                    <Typography 
                        variant='subtitle1'
                        justifyContent='center'
                        display='flex'
                        sx={{m:1,}}
                        >
                        You must login to view the {fromText} page
                    </Typography>
                </Container>
                {
                    hasAccount ? 
                    <Login />
                    :
                    <Signup />
                }

                <Box
                >
                    <Container>
                        <Link 
                            href="#" 
                            onClick={() => setHasAccount((status) => !status)}
                            variant="body2" 
                            display='flex'
                            justifyContent='flex-end'
                            sx={{ mt: 1, color: (t) => t.palette.mode === 'light' ? 
                                t.palette.success.dark : 
                                t.palette.success.main
                                }}
                            >
                            {hasAccount ? "Don't have an account? Sign Up" : "Already have an account? Sign in"}
                        </Link>
                    </Container>
                    
                    <Button
                        disableElevation
                        type="submit"
                        fullWidth
                        variant="outlined"
                        size='large'
                        color='google'
                        startIcon={<GoogleIcon />}
                        sx={{ mt: 5, mb: 2, }}
                        onClick={(e) => handleUseGoogle(e)}
                    >
                        Continue with Google
                    </Button>
                </Box>
            </Grid>
            <SEO 
                title='mavupload - Authentication'
                description='Upload Amazing and high quality artworks'
                type='Image'
                name='mavupload'
                keywords={keywords}
            />
        </Grid>
    )
}

export default Authentication