import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/muiTheme';
import { MotionImageList, MotionImageListItem } from '../../utils/motionMui';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import Backdrop from '@mui/material/Backdrop';
import { motion } from 'framer-motion';
import Modal from './Modal';

const ArtworkGrid = (props) => {
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [selectedArt, setSelectedArt] = useState(null);
    const [open, setOpen] = useState(false);
    const render = useRef(1);

    const handleClose = () => {
      setSelectedArt(null);
      setOpen(false);
    };
    const handleToggle = () => {
      setOpen( state => !state);
    };

    useEffect(() => {
        render.current = render.current + 1;
    }, [open, selectedArt])

    console.log(render.current)

    return (
        <>
        <MotionImageList
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1, }}
            transition={{ ease: "easeOut", duration: 1 }} 
            cols={matches ? 3 : 1}
            sx={{
                m: 0, 
                p: 0,
                gridTemplateColumns: {
                    md: "repeat(2, 1fr)",
                    sx: "repeat(1, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(1, 1fr)",
                    xxs: "repeat(1, 1fr)",
                }
            }} 
            gap={10}
        >
            <MotionImageListItem 
                key="Subheader" 
                cols={matches ? 3 : 1}
                sx={{
                }}
            >
                <ListSubheader 
                    component="div"
                    key="subheaderList"
                    sx={{ 
                        fontSize: '.8rem',
                        pl:0,
                        color: 'google.main',
                    }}
                >
                    {props.title}
                </ListSubheader>
            </MotionImageListItem>
            {
                props.docs && props.docs.map((item) => (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1, }} 
                        transition={{ ease: "easeOut", duration: 1 }} 
                        key={"motion" + item.id}
                    >
                        <ImageListItem 
                            className='img__wrap'
                            key={item.id} 
                            sx={{
                                overflow:'hidden',
                                opacity:0.85,
                                borderRadius: '.2rem',
                                ":hover": {cursor: 'pointer'}
                            }}
                            onClick={() => setSelectedArt(item)}
                        >
                            <img
                                src={`${item.images_url}?w=248&fit=crop&auto=format`}
                                srcSet={`${item.images_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                                onClick={handleToggle}
                            />
                            <ImageListItemBar
                                className='grid__item'
                                title={item.title}
                                subtitle={'Posted by '+ item.artist_name}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`like ${item.title}`}
                                        onClick={() => {
                                            props.setLikedArts(item)
                                        }}
                                    >
                                        <FavoriteRoundedIcon />
                                    </IconButton>
                                }
                                sx={{
                                    opacity: 0,
                                    ":hover": {opacity: 1}
                                }}
                            />
                        </ImageListItem>
                    </motion.div>
                ))
            }
        </MotionImageList>
        <Backdrop
            sx={{ 
                color: 'primary.light', 
                zIndex: (theme) => theme.zIndex.drawer + 1 ,
            }}
            open={open}
            onClick={handleClose}
        >
            { selectedArt && 
                (
                    <Modal selectedArt={selectedArt} />
                )
            }
        </Backdrop>
        </>
    )
}

export default ArtworkGrid