import React from 'react'
import{ Routes, Route } from 'react-router-dom';
import Layout from '../layout/Layout'
import Dashboard from '../pages/Dashboard/Dashboard';
import Uploads from '../pages/Upload/Upload';
import Authentication from '../pages/Authentication/Authentication';
import NoMatch from '../pages/NoMatch/NoMatch';
import Profile from '../pages/Profile/Profile';
import RequireAuth from '../pages/Authentication/RequireAuth';
import AuthProvider from '../pages/Authentication/AuthProvider';

const Routers = () => {
  return (
    <AuthProvider >
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route exact path='/authenticate' element={<Authentication/>}></Route>
                <Route path='/dashboard' element={<Dashboard/>}></Route>
                <Route 
                    path='/upload' 
                    element={
                        <RequireAuth>
                            <Uploads/>
                        </RequireAuth>
                    }
                ></Route>
                <Route 
                    path='/profile' 
                    element={
                        <RequireAuth>
                            <Profile/>
                        </RequireAuth>
                    }
                ></Route>
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    </AuthProvider>
  )
}

export default Routers