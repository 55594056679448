import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';


const SignupForm = (from) => {

  const navigate = useNavigate()
  const auth = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();


  const handleSignUp = (e) => {
    e.preventDefault();
    const newUserInfo = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
    }
    
    auth.signUp(newUserInfo, () => {
      navigate(from, { replace: true })
    })
  }

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        my: 4,
        mb: 1 ,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Box 
        component="form" 
        onSubmit={(e) => handleSignUp(e)}
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              id="firstName"
              type="text"
              label="First Name"
              autoFocus
              color='google'
              sx={{ mt: 1, backgroundColor: (t) => t.palette.mode === 'light' ? 
                t.palette.background.main : 
                t.palette.background.dark
              }}
              inputRef={firstNameRef}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              type="text"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              color='google'
              sx={{ mt: 1, backgroundColor: (t) => t.palette.mode === 'light' ? 
                t.palette.background.main : 
                t.palette.background.dark
              }}
              inputRef={lastNameRef}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              color='google'
              sx={{ mt: 1, backgroundColor: (t) => t.palette.mode === 'light' ? 
                t.palette.background.main : 
                t.palette.background.dark
              }}
              inputRef={emailRef}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl 
              fullWidth 
              required 
              color='google'
              sx={{ mt: 1, backgroundColor: (t) => t.palette.mode === 'light' ? 
                t.palette.background.main : 
                t.palette.background.dark
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                required
                inputRef={passwordRef}
              />
            </FormControl>
            <Grid item xs={12} sx = {{my: 1}}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="google" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            size='large'
            color='secondary'
            startIcon={<LockOpenIcon />}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Grid>
      </Box>
    </Box>
  )
}

export default SignupForm