import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import ArtworkGrid from '../../components/ui/ArtworkGrid';
import useFirestore from '../../firebase/useFirestore';
import SEO from '../../utils/SEO';
import { artworkData } from '../../data/ArtworksUnsplash'
import { keywords } from '../../utils/keywordsSEO';

const Dashboard = () => {
    const { docs } = useFirestore('artworks_projects');
    const [likedArtworks, setLikedArtworks] = useState([]);
    const handleLikes = (item) => {
        setLikedArtworks( oldVal => [
            item,
            ...oldVal,
        ])
    }

    useEffect(() => {
        document.title = 'mavupload - Dashboard';
    }, []);

    useEffect(() => {
        console.log(likedArtworks)
    })
    

  return (
    <Container 
      maxWidth="100%"
      sx={{
        minHeight: '25rem',
      }}
    >
        <ArtworkGrid docs={docs[1] ? docs : artworkData} title="Recent Artworks" setLikedArts = {handleLikes}/>

        <SEO 
          title='mavupload - dashboard'
          description='Amazing and high quality artworks, Portrait, Painting, Drawing'
          type='Image'
          name='mavupload'
          keywords={keywords}
        />
    </Container>
  )
}

export default Dashboard