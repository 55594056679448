import React from 'react';
import Logo from '../../components/logo/Logo';
import AppBar from '@mui/material/AppBar';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Grid from '@mui/material/Grid'; // Grid version 1
import NavBar from '../../components/navbar/Navbar';
import AuthStatus  from "../../pages/Authentication/AuthStatus";


function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props) => {
  return (
    <HideOnScroll {...props}>
    <AppBar 
      className='header'
      position="sticky"
      elevation={0}
      sx={{
        color: 'google.main',
        bgcolor: '#fff',
        opacity: .90,
        borderRadius: '',
        fontSize: '.8rem',
        display: "flex",
      }}
    >
        <Grid 
          container
          sx={{
            display: 'flex',
            flexFlow: 'row, nowrap',
            p: '.3rem',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid 
            item
            sx={{
            }}
          >
            <Logo />
          </Grid>
          <Grid 
            item
          >
            <NavBar />
          </Grid>
          <Grid 
            item
            sx={{
              display: 'flex',
            }}
          >
            <AuthStatus />
          </Grid>
        </Grid>
    </AppBar>
    </HideOnScroll>
  )
}

export default Header