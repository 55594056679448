import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import UploadArtwork from './UploadArtwork';
import { useUpdateArtContext } from '../../context/ArtContext';

const ProgressBar = () => {
  const { progress } =  UploadArtwork();
  const updateState = useUpdateArtContext();

  console.log(progress)

  useEffect(() => {
    if(progress >= 100) {
    updateState.setTitle('');
    updateState.setDescription('');
    updateState.setImage(null);
    updateState.setError(null);
    updateState.setUploadedUrl(null);
    updateState.setUploading(false);
    updateState.setUploaded(true);
  }
  }, [progress, updateState])
  

  return (
    <motion.div className="progress__bar"
      initial={{ width: 0 }}
      animate={{ width: progress + '%' }}
    ></motion.div>
  );
} 

export default ProgressBar;