import React, { useMemo} from 'react';
import { useAuth } from '../../context/AuthContext';
import SEO from '../../utils/SEO';
import userLogo from '../../assets/images/icons/21-avatar.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import { Typography } from '@mui/material';
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PermPhoneMsgRoundedIcon from '@mui/icons-material/PermPhoneMsgRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { useFirestoreUser } from '../../firebase/useFirestore';
import mavLogoTrans from '../../assets/images/Logo-Color-trans.png';
import ArtworkGrid from '../../components/ui/ArtworkGrid';
import { MotionGrid, MotionPaper, MotionAvatar } from '../../utils/motionMui';
import { keywords } from '../../utils/keywordsSEO';


const Profile = () => {

    const auth = useAuth();

    const { docs } = useFirestoreUser('artworks_projects', auth.user.uid);

    const randomImageUrl = useMemo(() => {
        const rand = Math.floor(Math.random() * docs.length);
        const url = (docs && docs[rand]) ? `url(${docs[rand].images_url})` : `url(${mavLogoTrans})`
        return url;
    }, [docs])

  return (
    <MotionGrid
      container
      initial={{ opacity: 0,}}
      animate={{ opacity: 1,}}
      transition={{ ease: "easeOut", duration: 1 }}
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        m: 0,
        p: 0,
        height: '80vh'
      }}
    >
        <ScopedCssBaseline />
        <Grid item 
            maxWidth="100%"
            sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
            m: 0,
            p: 0,
            }}
        >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}
        >
          <MotionPaper 
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1, }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            sx={{
              mb: '2rem',
              lineHeight: '60px', 
              height: { md: '16rem', xs: '12rem'},
              width: '100%', 
              maxWidth: {xs: '100%', md: '100%'},
              backgroundImage: randomImageUrl,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.primary.light : t.palette.primary.dark,
              backgroundSize: docs[1] ? 'cover' : 'contain',
              backgroundPosition: 'center',
            }} 
            elevation={0}
              
          >
            <Grid container>
              <MotionGrid 
                item xs
              >
                <Chip 
                  variant="outlined" 
                  clickable
                  label="Favorites"
                  size="medium" 
                  color='error'
                  sx={{m: '.5rem'}}
                  icon={
                    <IconButton size='small'>
                      <Badge 
                        color="secondary"
                        badgeContent={20} 
                        max={999}
                        
                      >
                        <FavoriteRoundedIcon 
                          color='error' 
                          fontSize="medium" 
                        />
                      </Badge>
                    </IconButton>} 
                />
              </MotionGrid>
              <MotionGrid 
                item
              >
                <Chip 
                  variant="outlined" 
                  clickable
                  label="Artworks"
                  size="medium" 
                  color='primary'
                  sx={{m: '.5rem'}}
                  icon={
                    <IconButton size='small'>
                      <Badge 
                        color="primary"
                        badgeContent={docs.length} 
                        max={999}
                        size="small"
                      >
                        <PaletteRoundedIcon 
                          color='google' 
                          fontSize="medium" 
                        />
                      </Badge>
                    </IconButton>
                  } 
                />
              </MotionGrid>
            </Grid>
            <Grid container>
              <MotionAvatar 
                initial={{ opacity: 0, x: -40,}}
                animate={{ opacity: 1, x: 0, }}
                transition={{ ease: "easeOut", duration: 1 }}
                sx={{ 
                  m: 1, 
                  bgcolor: 'success.main',
                  width: '3.5rem', 
                  height: '3.5rem',
                  position: 'relative',
                  bottom: {md: '-10rem', xs: '-6rem'},
                  left: '0.3rem',
                }}
                alt="Remy Sharp"
                src={auth.user.photoURL ? auth.user.photoURL : userLogo}
              >
                <AccountCircleSharpIcon />
              </MotionAvatar>
              <Typography component="h5" variant="subtitle"
                className='name__typo'
                color="primary.dark"
                sx={{
                  position:'relative',
                  top: {md: '10.5rem', xs: '6.5rem'},
                  p: '0.5rem',
                }}
              >
                {auth.user.displayName}
              </Typography>
            </Grid>
          </MotionPaper>
        </Box>
      </Grid> 
    <Grid item
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <MotionGrid 
          maxWidth={'md'}
          initial={{ opacity: 0, y: -40, }}
          animate={{ opacity: 1, y: 0,}}
          transition={{ ease: "easeOut", duration: 1 }}
          container 
          className='grid__info'
          sx={{
            m: '1rem',
            backgroundColor: 'primary.subtle',
            borderRadius: '1rem',
          }}
        >
          <Grid item xs>
            <ListItem color='primary.subtle'
              sx={{
                color: 'var(--google_blue)'
              }}
            >
              <ListItemAvatar sx={{minWidth: '26px', p: '0.5rem'}}>
                <Avatar  
                  sx={{
                    bgcolor: 'primary.light', width: 35, height: 35,
                  }}
                >
                  <EmailRoundedIcon color='primary' fontSize='small' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText  
                sx={{
                  color:'var(--google_blue)'
                }}
                primary="Email"
                secondary={auth.user.email ? auth.user.email: "None"}
              />
            </ListItem>
          </Grid>
          <Grid item xs>
            <ListItem >
              <ListItemAvatar sx={{minWidth: '26px', p: '0.5rem'}}>
                <Avatar 
                  sx={{
                    bgcolor: 'primary.light', width: 35, height: 35,
                  }}
                >
                  <PermPhoneMsgRoundedIcon color='primary' fontSize='large'  />
                </Avatar>
              </ListItemAvatar >
              <ListItemText
                sx={{
                  color:'var(--google_blue)'
                }}
                primary="Phone"
                secondary={auth.user.phone ? auth.user.phone: "None"}
              />
            </ListItem>
          </Grid>
          <Grid item xs>
            <ListItem>
              <ListItemAvatar
                sx={{minWidth: '26px', p: '0.5rem'}}
              >
                <Avatar 
                  sx={{
                    bgcolor: 'primary.light', width: 35, height: 35,
                  }}
                >
                  <WorkRoundedIcon color='primary' fontSize='large'  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  color:'var(--google_blue)'
                }}
                primary="Profession"
                secondary={auth.user.activity ? auth.user.activity : "Artist"}
              />
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem>
              <ListItemAvatar 
                sx={{minWidth: '26px', p: '.5rem'}}>
                <Avatar 
                  sx={{
                    bgcolor: 'primary.light', width: 35, height: 35,
                  }}
                >
                  <LockOpenRoundedIcon color='primary' fontSize='large'  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  color:'var(--google_blue)'
                }}
                primary="Last Login"
                secondary={auth.user.metadata ? auth.user.metadata.lastSignInTime : "None"}
              />
            </ListItem>
          </Grid>
        </MotionGrid>
        </Grid>
        <Grid item>
            <Container 
            maxWidth="100%"
            sx={{
                mt:'1rem',
                height: '50%',
            }}
            >
            <ArtworkGrid docs={docs} title='My Artworks' />
            </Container>
        </Grid>

        <SEO 
          title='mavupload - profile'
          description='Amazing and high quality artworks, Unique artists,'
          type='Image'
          name='mavupload'
          keywords={keywords}
        />
    </MotionGrid>
  )
}

export default Profile