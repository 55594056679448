import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Grid from '@mui/material/Grid';

const Layout = () => {
  return (
    <Grid 
      container
      maxWidth='100vw'
      maxHeight='100vh'
    >
      <Header/>
      {/* An <Outlet> renders whatever child route is currently active,
      so you can think about this <Outlet> as a placeholder for
      the child routes we defined above. */}
      <Outlet />
      <Footer />
    </Grid>
  )
}
export default Layout