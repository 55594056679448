

export const keywords = [
    'Elements of design',
    'Design principles',
    'Graphic design',
    'Design elements',
    'Elements of art',
    'design process',
    'Wonder of Art',
    'Women in red painting',
    'Women in painting',
    'Good times painting',
    'Madam X painting',
    'Visual artist',
    'Aesthetic drawing',
    'Original portrait',
    'Sunset painting', 
    'Sunset illustration',
    'Petite Madam',
    'Self portrait',
    'Couple portrait',

]